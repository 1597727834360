import '@splidejs/react-splide/css'

import React, { Fragment, useRef, useState } from 'react'
import CollapsibleContent from '../CollapsibleContent/CollapsibleContent'
import Image from '../Image/Image'
import Gallery from '../Gallery/Gallery'
import CollapsibleText from '../CollapsibleText/CollapsibleText'
import slugify from 'react-slugify';
import { Link } from 'react-router-dom'
import { format } from "date-fns";
import ImageGrid from '../ImageGrid/ImageGrid'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import ContentCarousel from '../ContentCarousel/ContentCarousel'
// import ElementInViewport from './ElementInViewport'
import ContentTxt from './ContentTxt'
import { useWindowSize } from 'usehooks-ts'

const PageContent = ({title, slug, contentItems, teamPositions, teamPosts, jobPosts, currentJob, awardPosts, newsPosts}) => {
  
  const subMenu = contentItems?.filter(el => el.acf_fc_layout === 'buro_content_txt' && el.submenuTxt !== '')

  const { width } = useWindowSize()
  const refGallery = useRef(null)

  const [activeVita, setActiveVita] = useState(null)
  const [isInViewport, setIsInViewport] = useState(null);

  // useEffect(() => {
  //   console.log('isInViewport', isInViewport)
  // }, [isInViewport])

  return (
    contentItems?.map((el, i) => <>
      {i === 1 && subMenu?.length >= 1 && 
        <div key={'submenu'} className='hidden lg:block lg:sticky top-0 z-10 w-full pt-[2.5vw] bg-black max-lg:overflow-auto'>
          <div className='relative w-full'>
            <h1 className='uppercase'>{title}</h1>
          </div>
          
          <ul className='flex flex-row !list-none gap-x-6 lg:gap-x-20 !m-0 py-4 lg:py-8'>
            {subMenu?.map((item, i) => 
              <li key={`list-item-${i}`} className='text-white text-medium'>
                <button className={`${slugify(item.submenuTxt) === isInViewport ? 'underline decoration-1 underline-offset-4 text-xl' : '!no-underline !lg:hover:underline !lg:hover:decoration-2 !lg:hover:underline-offset-2'}`} onClick={() => {
                  const anchor = document.getElementById(slugify(item.submenuTxt))
                  anchor?.scrollIntoView({
                    behavior: 'smooth'
                  })
                }}>
                  {item.submenuTxt}
                </button>
              </li>)}
          </ul>
        </div>}

      {(el.acf_fc_layout === 'buro_content_accordion') &&
        <div key={i} id={`${el.acf_fc_layout}-${i}`} className={`relative ${el.content_width === '12' ? 'w-full' : `lg:w-${el.content_width}/12`}`}>
          <CollapsibleContent 
            elKey={i} 
            isOpen={null}
            collapsibleHeader={el.content_accordion_headline} 
            collapsibleBody={el.content_accordion_el} 
            type={'page'}
          /> 
        </div>}

      {el.acf_fc_layout === 'buro_content_txt' && 
        <Fragment key={i}>
          <ContentTxt 
            layout={el.acf_fc_layout}
            setIsInViewport={setIsInViewport} 
            anchor={el.submenuTxt !== '' ? slugify(el.submenuTxt) : null} 
          >
            <div className={`relative ${el.content_width === '12' ? 'w-full' : `lg:w-${el.content_width}/12`} flex ${el.elements_grid ? 'flex-wrap justify-between' : 'flex-col'}`}>
              {el.content_elements?.map((el, i) => 
                <Fragment key={i}>
                  {el.acf_fc_layout === 'content_element_images' && el.images &&
                    el.images?.length > 1 ?
                      el.gallery_grid ?
                        <Gallery
                          images={el.images}
                          type='fade'
                          fullscreen={true}
                          arrows={false}
                          autoplay={5000}
                          autoPlayActive={true}
                          isProject={false}
                        />
                        :
                        <ImageGrid images={el.images} />
                    :                          
                    <div className={`relative lg:w-${el.content_width}/12`}>
                      <Image img={el.images ? el.images[0] : null} isModal={false} />
                    </div>}

                  {el.acf_fc_layout === 'content_element_txt' && el.txt && 
                    <>
                      <div 
                        className={`relative w-full ${el.two_cols ? 'lg:columns-2 gap-x-8' : ''}`} 
                        dangerouslySetInnerHTML={{__html: el.txt.replaceAll(
                          'href="https://cms.pws.eu/',
                          'href="https://www.pws.eu/'
                        )}}
                      />
                      
                      {el.collapsible && 
                        <CollapsibleText
                          elKey={i} 
                          isOpen={null}
                          collapsibleHeader={el.collapsibleButton} 
                          type={''}
                        >
                          <div className='my-3' dangerouslySetInnerHTML={{__html: el.collapsibleTxt}} />
                        </CollapsibleText>}
                    </>}

                  {el.acf_fc_layout === 'content_element_space' &&
                    <div
                      className='relative w-full'
                      style={{ height: `${el.space_value}px`}}
                    />}
                  
                  {/* {(slug === 'jobs' || slug === 'karriere') && i === 1 && currentJob != null && 
                    <div className='relative w-full my-6 border-t border-white lg:mt-10 lg:mb-20'>
                      <CollapsibleContent
                        collapsibleHeader={currentJob?.[0]?.title?.rendered}
                        jobLocation={currentJob?.[0]?.acf?.job_buro}
                        jobDate={currentJob?.[0]?.date}
                        type={'page'}
                        elKey={currentJob?.[0]?.slug}
                        isOpen={currentJob?.[0]?.slug}
                      >
                        {currentJob?.[0]?.acf?.job_description && <div className={`relative w-full lg:flex lg:flex-col`} dangerouslySetInnerHTML={{__html: currentJob?.[0]?.acf?.job_description}}/> }
                        {currentJob?.[0]?.acf?.job_pdf && <p className='text-sm lg:text-base'><a target='_blank' rel='noreferrer' href={currentJob?.[0]?.acf?.job_pdf} className='text-sm underline underline-offset-2'>Jobbeschreibung</a></p>}
                      </CollapsibleContent>
                    </div>} */}
                </Fragment>)}
                
              {slugify(el.submenuTxt) === 'team' && teamPositions?.map((position, _) => {
                const positionTeamItems = teamPosts?.filter(el => el.positionCategory?.[0]?.name === position.name)
                return (
                  <div key={position.term_id} className='relative w-full'>
                    <p className='mt-4 lg:mt-12 !mb-5'>{position.name}</p>

                    {position.name === "Mitarbeiter" ? 
                      <Splide 
                        ref={refGallery}
                        tag='section'
                        hasTrack={false}
                        className={`relative z-0 w-full h-auto`} 
                        aria-label='Bildergalerie'
                        options={{
                          type: 'loop', 
                          perPage: width >= 1024 ? 6 : 2,
                          padding: 0,
                          gap: width >= 1024 ? '40px' : '20px',
                          speed: 1250,
                          // drag: 'free',
                          autoScroll: {
                            speed: 2.5
                          },
                          rewind: true,
                          arrows: false,
                          pagination: false
                        }}
                        extensions={ { AutoScroll } }
                      >
                        <SplideTrack>
                          {positionTeamItems?.map((el, i) => 
                            <SplideSlide key={i} >
                                <div className='relative flex flex-col w-full gap-y-4'>
                                  {/* {el.img && <Image img={el.img ? el.img : null} isModal={false} isPost noBlur />} */}
                                  {el.img && <img src={el.img.sizes?.['resp-small']} alt={el.name} className={'block relative w-full h-full object-center '} />}
                                  <div className=''>
                                    <p className='mb-5'>{el.name}</p>
                                    <p className='!text-sm'><a href={`mailto:${el.email}`} className='!no-underline'>{el.email}</a></p>
                                  </div>
                                </div>
                            </SplideSlide>)}                          
                        </SplideTrack>
                      </Splide>
                      : 
                      <div className='relative flex flex-wrap overflow-x-auto max-w-none gap-x-5 lg:gap-x-10'>
                        {positionTeamItems?.map((el, i) => 
                          <div key={i} className={`relative flex flex-row gap-y-4 ${activeVita === i ? 'basis-full lg:basis-[calc(41.3333%-33.3333px)] overflow-auto' : 'basis-[calc(50%-10px)] lg:basis-[calc(16.6667%-33.3333px)]'}`}>    
                            <div className={`flex flex-row flex-wrap gap-x-0 w-full`}>
                              <div className={`relative ${activeVita === i ? 'basis-[calc(50%-10px)] lg:grow lg:basis-[calc(16.6667%-16.6667px)]' : 'basis-full'}`}>
                                <Image img={el.img ? el.img : null} isModal={false} isPost preview="" />
                                <p className='mt-5 !mb-0'>{el.name}</p>
                                <p className='!mb-5'>{el.position}</p>
                                <p className='!text-sm !mb-1'><a href={`mailto:${el.email}`} className='!no-underline'>{el.email}</a></p>
                                {el.vita !== '' && <button 
                                  type='button'
                                  className={`flex items-center gap-x-3 font-folioLight leading-none py-2 focus:outline-none focus:ring-0 focus:ring-inset-0 text-white w-12 h-12`}
                                  onClick={() => setActiveVita(activeVita === i ? null : i)}
                                >
                                  Vita
                                  <svg className={`block w-full h-full ${activeVita === i ? '' : '-rotate-45'}`} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
                                    {activeVita === i ? <path strokeLinecap="square" strokeLinejoin="square" strokeWidth="3" d="M 0 12 L 24 12"/> : <path strokeLinecap="square" strokeLinejoin="square" strokeWidth='4' d="M 0.339 23.661 L 23.661 0.339 M 0.339 0.339 L 23.661 23.661" />}
                                  </svg>
                                </button>}
                              </div>
                              {el.vita !== '' && activeVita === i && <div className='basis-full lg:pl-10 lg:basis-2/5 grow' dangerouslySetInnerHTML={{__html: el.vita}} />}
                            </div>
                          </div>)}
                      </div>}
                  </div>
                )
              })}

              {slugify(el.submenuTxt) === 'news' &&
                <div key={i} className='relative w-full'>
                  {newsPosts && <ContentCarousel data={'news'} content={newsPosts} />}
                </div>}

              {slugify(el.submenuTxt) === 'jobs' &&
                <div key={i} className='relative w-full mt-10'>
                  {jobPosts?.map((job, i) => {
                    const date = format(new Date(job.date), "MM/yyyy") 
                    return (
                      <Link key={i} to={`/jobs/${job.slug}`} preventScrollReset={false} state={{jobId: el.id, jobSlug: el.slug}} className={`flex flex-col max-lg:flex-col-reverse w-full lg:w-10/12 xl:w-8/12 py-3 lg:py-5 ${i === 0 ? 'border-y' : 'border-b'} border-white gap-x-8 !no-underline	`}>
                        <p className='!mb-0 !text-sm'>{job.acf.job_buro}</p>
                        <p className='!mb-0 !text-base font-folioMedium'>{job.title.rendered}</p>
                        <p className='!mb-0 !text-sm'>{date}</p>
                      </Link>
                    )
                  })}
                </div>}
              
              {slugify(el.submenuTxt) === 'auszeichnungen' &&
                <div key={i} className='relative w-full'>
                  {awardPosts && <ContentCarousel data={'awards'} content={awardPosts} />}
                </div>}
            </div>
          </ContentTxt>
        </Fragment>}
    </>)
  )
}

export default PageContent






{/* {newsPosts && <TabbedContent data={'news'} content={newsPosts} tabs={newsYears ? newsYears : null} />} */}
{/* <TabbedContent data={'awards'} content={awardPosts}/> */}

// switch (el.acf_fc_layout) {
//   case 'buro_content_txt':
//     return (
//       <div 
//         key={i} 
//         id={i} 
//         // ref={el => contentRefs.current[i] = el} 
//         className={`relative lg:w-${el.content_width}/12 mb-8 text-sm text-white text-left`}
//       >            
//         {el.content_elements?.map(el => (
//           <>
//             {el.images?.length > 1 ? <Gallery images={el.images} autoplay={true} /> : <Image img={el.images ? el.images[0] : null} isModal={false} />}
//             {el.txt && <div dangerouslySetInnerHTML={{__html: el.txt}}></div>}
//           </>
//         ))}
//       </div>
//     )
  
//   case 'buro_content_accordion':
//     return (
//       <div 
//         key={i} 
//         id={i} 
//         // ref={el => contentRefs.current[i] = el} 
//         className={`relative ${el.content_width === '12' ? 'w-full' : `lg:w-${el.content_width}/12`} text-sm text-white text-left`}
//       >
//         <CollapsibleContent collapsibleHeader={el.content_accordion_headline} collapsibleBody={el.content_accordion_el} type={'page'} elKey={i} isOpen={null} />
//       </div>
//     )
  
  // case 'buro_content_img':
  //   return (
  //     <div 
  //       id={i} 
  //       key={i} 
  //       // ref={el => contentRefs.current[i] = el} 
  //       className={`relative ${el.buro_content_col_size === '12' ? 'w-full' : `lg:w-${el.buro_content_col_size}/12`} mb-8 text-sm text-white text-left`}
  //     >
  //       {el.buro_img && <Image img={el.buro_img} isModal={false} />}
  //     </div>
  //   )
  
  // default:
  //   return null
// }