import React, { useState, useRef, useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { useFetch } from '../../useFetch'
import CollapsibleContent from '../CollapsibleContent/CollapsibleContent'
import PageContent from '../PageContent/PageContent'

const Page = () => {
  const { slug, postname } = useParams()

  const page = useFetch(`https://cms.pws.eu/wp-json/wp/v2/pages/?slug=${slug}`)
  const jobs = useFetch('https://cms.pws.eu/wp-json/wp/v2/jobs')
  const awards = useFetch('https://cms.pws.eu/wp-json/pws/v1/awards')
  const news = useFetch('https://cms.pws.eu/wp-json/pws/v1/news')
  const team = useFetch('https://cms.pws.eu/wp-json/pws/v1/team')
  const teamTerms = useFetch('https://cms.pws.eu/wp-json/pws/v1/team_positions')  
  
  const refPage = useRef(null)
  const initialJobOpen = postname ? jobs?.data?.filter(el => el.slug === postname) : null

  const [pageTitle, setPageTitle] = useState(null)
  const [pageContent, setPageContent] = useState(null)
  const [newsPosts, setNewsPosts] = useState(null)
  const [jobPosts, setJobPosts] = useState(null)
  const [awardPosts, setAwardPosts] = useState(null)
  const [teamPositions, setTeamPositions] = useState(null)
  const [teamPosts, setTeamPosts] = useState(null)
  
  useEffect(() => {
    if (!page.loading) {
      setPageTitle(page?.data?.[0]?.title?.rendered)
      setPageContent(page?.data?.[0]?.acf?.buro_content)

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [page]);

  // useEffect(() => {    
  //   // if (!page.loading && !teamTerms.loading && !awards.loading && !team.loading && !news.loading && !jobs.loading) 
  //   //   setLoading(false)
  // }, [pageContent]) //jobs, awards, news, teamTerms, team, ,

  useEffect(() => {
    if (!teamTerms.loading)
      setTeamPositions(teamTerms?.data)
  }, [teamTerms]);

  useEffect(() => {
    if (!team.loading) 
      setTeamPosts(team?.data)
  }, [team]);

  useEffect(() => {
    if (!news.loading) 
      setNewsPosts(news?.data)
  }, [news]);

  useEffect(() => {
    if (!jobs.loading) 
      setJobPosts(jobs?.data)
  }, [jobs]);
  
  useEffect(() => {
    if (!awards.loading)
      setAwardPosts(awards?.data)
  }, [awards]);

  // useEffect(() => {
    
  // }, []);
  
  return (
    <div ref={refPage} className={`relative min-h-screen text-sm text-left max-lg:mt-8 ${slug !== 'buero' ? 'pt-[2.5vw]' : ''} page`}> 
      <div className='flex flex-wrap w-full gap-y-10 lg:gap-y-20 mb-[33vh]'>
        <PageContent
          title={pageTitle}
          slug={slug}
          contentItems={pageContent}
          teamPositions={teamPositions}
          teamPosts={teamPosts}
          jobPosts={jobPosts}
          awardPosts={awardPosts}
          newsPosts={newsPosts}
        />

        {(slug === 'jobs' || slug === 'karriere') && jobPosts &&
          <div className='relative w-full mt-10 border-t border-white xl:w-2/3'>
            {initialJobOpen &&
              <CollapsibleContent
                collapsibleHeader={initialJobOpen?.[0]?.title?.rendered}
                jobLocation={initialJobOpen?.[0]?.acf?.job_buro}
                jobDate={initialJobOpen?.[0]?.date}
                type={'page'}
                elKey={initialJobOpen?.[0]?.slug}
                isOpen={initialJobOpen?.[0]?.slug}
                initialJobOpen={initialJobOpen}
              >
                {initialJobOpen?.[0]?.acf?.job_description && <div className={`relative w-full lg:flex lg:flex-col`} dangerouslySetInnerHTML={{__html: initialJobOpen?.[0]?.acf?.job_description}}/> }
                {initialJobOpen?.[0]?.acf?.job_pdf && <p className='text-sm lg:text-base'><a target='_blank' rel='noreferrer' href={initialJobOpen?.[0]?.acf?.job_pdf} className='text-sm underline underline-offset-2'>Jobbeschreibung</a></p>}
              </CollapsibleContent>}
            
            {jobPosts?.filter(el => el.slug !== postname).map((job, i) => (
              <Fragment key={i}>
                <CollapsibleContent
                  collapsibleHeader={job.title.rendered}
                  jobLocation={job.acf.job_buro}
                  jobDate={job.date}
                  type={'page'}
                  elKey={job.id}
                  isOpen={initialJobOpen?.[0]?.id}
                >
                  {job.acf.job_description &&
                    <div
                      className={`relative w-full lg:flex lg:flex-col`}
                      dangerouslySetInnerHTML={{__html: job.acf.job_description}}
                    />}
                  
                  {job.acf.job_pdf && 
                    <p className='text-sm lg:text-base'>
                      <a target='_blank' rel='noreferrer' href={job.acf.job_pdf} className='text-sm underline underline-offset-2'>
                        Jobbeschreibung
                      </a>
                    </p>}
                </CollapsibleContent>
              </Fragment>
            ))}
          </div>}
      </div>
    </div>
  )
}

export default Page