import React, { useContext, useEffect, useRef, useState } from 'react'
import { ThemeContext } from '../../ThemeContext'
// import { PageScrolledContext } from '../../PageScrolledContext'
import { useLocation } from 'react-router-dom'
import { useWindowSize } from '@uidotdev/usehooks'
import LogoPeter from './LogoPeter'
import LogoW from './LogoW'
import LogoSchmidt from './LogoSchmidt'
import LogoPW from './LogoPW'
import LogoS from './LogoS'
import gsap from 'gsap'
import { useGSAP } from "@gsap/react";

function Logo() {
  
  const size = useWindowSize()

  const { setIntroEnd, hideLogoHorizontal, setShowLogo, menuOpen, showIntro } = useContext(ThemeContext)

  const { pathname } = useLocation()

  const refLogo = useRef(null)
  
  useGSAP(
    () => {
      if (pathname === '/' || pathname === '/projekte') {
        gsap.to('.logo-initial, .logo-letter', { 
          opacity: 1, 
          stagger: .1,
          duration: .15,
          ease: 'linear',
          delay: 1,
        })
  
        gsap.to('.logo-letter', { 
          opacity: 0, 
          duration: .3,
          ease: 'linear',
          delay: 3,
          onComplete: () => {
            size.width < 1024 && setIntroEnd(true)
          }
        })

        gsap.to('.logo-dot', { 
          opacity: 1, 
          duration: .15,
          ease: 'linear',
          delay: 3.15,
          onComplete: () => {
            size.width >= 1024 && setIntroEnd(true)
            setShowLogo(true)
          }
        })
      }
    },
    { dependencies: [showIntro], scope: refLogo }
  );

  return (
    <div ref={refLogo} className={`h-[calc(100vh-12.5vw)] lg:h-[calc(100vh-5vw)] inset-y-[6.25vw] lg:inset-y-[2.5vw] left-3 lg:left-[2.5vw] right-3 lg:right-[7.5vw] ${menuOpen ? 
      'fixed' 
      : 
      'absolute left-0'} ${hideLogoHorizontal ? 
        'lg:-translate-x-[calc(100%+2.5vw)] duration-500 ease-linear'
        : 
        'duration-750 ease-[cubic-bezier(0.25,0.25,0.75,0.75)]'} flex flex-col justify-between pointer-events-none transition-all z-50 logosPWS`}>
      <div className='relative w-full'>
        <LogoPeter /> 
        <LogoPW />
        <LogoW />
      </div>

      <div className='flex flex-col justify-end w-full'>
        <LogoSchmidt />
        <LogoS />
      </div>
    </div>
  )
}

export default Logo