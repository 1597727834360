import React, { useContext, useEffect, useRef, useState } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { ThemeContext } from '../../ThemeContext'
import { useWindowSize } from '@uidotdev/usehooks'
import Image from '../Image/Image'

import '@splidejs/react-splide/css'

const Gallery = ({images, type, fullscreen, ratio, autoplay, pagination, arrows, autoPlayActive, isPost, isProject, children}) => {

  const size = useWindowSize()
  const { hideLogoHorizontal, setHideLogoHorizontal, menuOpen } = useContext(ThemeContext)

  const refGallery = useRef(null)
  const refBtnPrev = useRef()
  const refBtnNext = useRef()
  
  const [hoveredArrow, setHoveredArrow] = useState(null)
  const [activeSlide, setActiveSlide] = useState(1)

  useEffect(() => {
    menuOpen ? refGallery?.current?.splide.Components.Autoplay.pause() : refGallery?.current?.splide.Components.Autoplay.play()
    if (isProject && !menuOpen) {
       activeSlide !== 1 && setHideLogoHorizontal(true)
    }
  }, [menuOpen, activeSlide, refGallery.current, setHideLogoHorizontal]);

  // useEffect( () => {
  //   if (refGallery.current) {
  //     autoPlayActive ? refGallery.current.splide.Components.Autoplay.play() : refGallery.current.splide.Components.Autoplay.pause()
  //   }
  // }, [autoPlayActive])

  return (
    images?.length > 1 &&
      <Splide 
        ref={refGallery}
        tag='section'
        hasTrack={false}
        className={`relative z-0 w-full ${fullscreen ? 'aspect-16/10 lg:h-[calc(100vh-2.5vw)] pt-[2.5vw]' : 'h-auto'}`}
        aria-label='Bildergalerie'
        options={{
          heightRatio: ratio ? ratio : false,
          type: type? type : 'loop', 
          easing: 'cubic-bezier(.1,.6,.4,1)',//cubic-bezier(0.250, 0.250, 0.750, 0.750)
          speed: 1000, //type === 'fade' && autoplay !== undefined ? 1000 : 500,
          autoplay: autoplay !== undefined ? true : false,
          pauseOnHover: false,
          interval: autoplay,
          // rewind: true,
          gap: isProject ? '2.5vw' : '',
          arrows: false,
          pagination: false
        }}
        onMove={() => setActiveSlide(refGallery?.current?.splide.index + 1)}
        // onMounted={splide => splide.Components.Autoplay.pause()}
      >
        <SplideTrack>
          {images?.map((el, i) => 
            <SplideSlide key={i} >
              <Image img={el} galleryImg={true} isPost={isPost} preview="" />
              {children}
            </SplideSlide>)}
        </SplideTrack>

        {pagination &&
          <div className='absolute bottom-0 right-0 z-10 slideIndex'>
            <p className='text-sm leading-none lg:text-medium'>{`${activeSlide}/${refGallery?.current?.splide.length}`}</p>
          </div>}

        {arrows && size.width > 1024 &&
          <div className="!block absolute inset-x-0 top-0 z-10 h-full text-black splide__arrows mix-blend-overlay ">
            <button ref={refBtnPrev} 
              onClick={ () => refGallery.current?.splide.Components.Controller.go('<')}
              onMouseEnter={() => setHoveredArrow(refBtnPrev)}
              onMouseLeave={() => setHoveredArrow(null)}
              className="splide__arrow splide__arrow--prev absolute !top-1/2 !w-1/2 !h-full !left-8 !bg-transparent text-left !opacity-100 !z-40 cursor-arrowLeft focus:!outline-none"
            />
            <button ref={refBtnNext}  
              onClick={ () => refGallery.current?.splide.Components.Controller.go('>')}
              onMouseEnter={() => setHoveredArrow(refBtnNext)}
              onMouseLeave={() => setHoveredArrow(null)}
              className="splide__arrow splide__arrow--next absolute top-1/2 !w-1/2 !h-full !right-8 !bg-transparent text-right !opacity-100 !z-40 !rotate-180 -translate-y-1/2 origin-center cursor-arrowRight focus:!outline-none"
            />
          </div>
        }
      </Splide>
  )
}

export default Gallery