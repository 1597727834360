import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import NewsContent from '../NewsContent/NewsContent'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/react-splide/css'
import Image from '../Image/Image'
import { LazyLoadImage } from 'react-lazy-load-image-component'

function deepFlatten(arr) {
  return arr.reduce((acc, obj) => {
    Object.values(obj).forEach(value => {
      if (Array.isArray(value)) {
        acc.push(...deepFlatten(value));
      } else {
        acc.push(value);
      }
    });
    return acc;
  }, []);
}

const CustomPagination = ({ slides, splideInstance, type }) => {
  // const [slides, setSlides] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (!splideInstance) return;

    // Fetch slides on mount
    // const slidesCount = splideInstance.Components.Elements.slides.length;
    // setSlides(Array.from({ length: slidesCount }));

    // Update the active index on slide change
    const updateIndex = () => setActiveIndex(splideInstance.index)
    splideInstance.on("move", updateIndex);

    return () => splideInstance.off("move", updateIndex);
  }, [splideInstance]);

  const handlePaginationClick = (index) => splideInstance.go(index);
  let counter = 0
  return (
    <div className="flex flex-wrap mb-10 custom-pagination gap-x-8">
      {slides.map((slide, index) => {
        return (
          type === 'news' ? 
            slide.year !== slides[index-1]?.year &&
              <button
                key={index}
                className={`text-base pagination-button ${index === activeIndex ? "underline underline-offset-4 decoration-1" : ""}`}
                onClick={() => handlePaginationClick(index)}
              >
                {slide.year}
              </button>
              
              :

              slide?.length !== 0 &&
                slide?.map((btn,i) => {
                  const paginationItem = counter++
                  return (
                    i === 0 && slide[0]?.[0]?.year && <button
                      key={i}
                      id={paginationItem}
                      className={`text-base pagination-button ${paginationItem-1 === activeIndex ? "underline underline-offset-4 decoration-1" : ""}`}
                      onClick={() => handlePaginationClick(paginationItem-1)}
                    >
                      {slide[0]?.[0]?.year}
                    </button>
                  )
                })
        )})}
    </div>
  );
};

const ContentCarousel = ({data, content}) => {

  const [splideInstance, setSplideInstance] = useState(null);
  const [fullContent, setFullContent] = useState(null)

  const awardSlides = content?.map((el) => {
    const year = el.year
    return el.awards?.map((el) => {
      const award = el.name
      return el.projects?.map((el) => {
        const slide = {
          project: el,
          year: year,
          award: award
        }
        return slide
      })
    })
  }) 


  const slides = data === 'awards' ? awardSlides : content

  useEffect(() => {
    console.log(slides)
  }, []);
  
  return (
    <>
      <Splide 
        tag='section'
        hasTrack={false}
        className={`relative z-0 w-full`} 
        aria-label={`${data}`}
        options={{
          type: 'slide', 
          autoWidth: true,
          padding: 0,
          gap: '20px',
          speed: 1250,
          rewind: false,
          arrows: false,
          pagination: false
        }}
        onMounted={splide => setSplideInstance(splide)}
      >
        <CustomPagination slides={data === 'awards' ? awardSlides : content} splideInstance={splideInstance} type={data} />
        
        <SplideTrack>
          {content?.map((el, i) => {
            const year = data === 'awards' && el.year
            return (
              data === 'news' ?
              <SplideSlide key={i} className={`w-full ${fullContent === i ? 'lg:w-2/5 min-[1920px]:w-1/6' : 'lg:w-1/5 min-[1920px]:w-1/6'} lg:aspect-1/2.5`}>
                <div className={`relative w-full h-full p-2 bg-[#676767] ${fullContent === i ? 'overflow-auto' : ''}`}>
                  <div className='relative cursor-pointer'>
                      {el.img?.[0] && 
                        <div className={`${el.img?.[0].width > el.img?.[0].height ? `${fullContent === i ? 'w-[calc(50%-10px)]' : 'w-full'}` : `${fullContent === i ? 'w-[calc(50%-10px)] mx-auto' : 'w-full lg:w-full'}` } relative aspect-3/2 mb-3`}>  
                          <LazyLoadImage
                            alt={el.img?.[0].alt ? el.img?.[0].alt : ''}
                            src={el.img?.[0].sizes['resp-small']}
                            className={`block relative ${el.img?.[0].width > el.img?.[0].height ? 'w-full h-full' : `mx-auto h-full`}`}
                            onClick={() => setFullContent(fullContent === i ? null : i)}
                          />
                        </div>}

                    <p className='!mb-1 text-sm'>{el.postdate}</p>
                    <p className='!mb-1 text-sm font-folioMedium uppercase'>{el.title}</p>
                    
                    <div className={`${fullContent === i ? '' : 'line-clamp-3'}`} dangerouslySetInnerHTML={{__html: el.txt}} />
                    
                    <div className='flex items-center w-full'>
                      <button 
                        type='button'
                        className={`font-folioLight leading-none mx-1 my-2 focus:outline-none focus:ring-0 focus:ring-inset-0 text-white ${fullContent === i ? 'rotate-0' : 'rotate-45'}`}//
                        onClick={() => setFullContent(fullContent === i ? null : i)}
                      >
                        <svg className='size-3' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
                          {fullContent === i ? 
                          <path strokeLinecap="square" strokeLinejoin="square" strokeWidth="3" d="M 0 12 L 24 12"/> 
                          : 
                          <path strokeLinecap="square" strokeLinejoin="square" strokeWidth='4' d="M 0.339 23.661 L 23.661 0.339 M 0.339 0.339 L 23.661 23.661" />}
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              :
              el.awards?.length >= 1 && el.awards?.map((el, i) => {
                const award = el.name
                return (
                  el.projects?.length >= 1 && el.projects?.map((el, i) => 
                    <SplideSlide key={i} className={`w-full lg:w-1/5 min-[1920px]:w-1/6 lg:aspect-1/2.5`}>
                      <div className={`relative w-full h-full p-2 bg-[#676767]`}>
                        <p className='!mb-3 text-sm font-folioMedium uppercase'>{year}</p>
                        <p className='!mb-3 text-sm font-folioMedium uppercase'>{award}</p>
                        {el.img && <LazyLoadImage
                          alt={el.img?.alt ? el.img?.[0].alt : ''}
                          src={el.img?.sizes['resp-small']}
                          className={`block relative mb-3 ${el.img?.width > el.img.height ? 'w-full' : 'md:w-2/3 mx-auto'}`}
                        />}
                        <p className='!mb-1 text-sm font-folioMedium uppercase'>{el.name}</p>
                        <p className='!mb-1 text-sm'>{el.location}</p>
                        <Link to={`/projekt/${el?.slug}`} className='block w-full !mb-1'>
                          zum Projekt
                        </Link>
                      </div>
                    </SplideSlide>)
                )
              })
            )    
          })}
        </SplideTrack>
      </Splide>
    </>
  )
}

export default ContentCarousel