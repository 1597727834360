import React, {useState, useRef, useEffect}  from 'react'
import Image from '../Image/Image'
import { Link } from 'react-router-dom'
import { useWindowSize } from '@uidotdev/usehooks'

const Project = ({content, type, firstItem, view, width, centered }) => {
  
  const size = useWindowSize()

  const refProject = useRef(null)
  
  const [viewStyles, setViewStyles] = useState('')

  useEffect(() => {
    switch (view) {      
      case 'list':
        return setViewStyles(firstItem ? 'list border-y border-w-1' : 'list border-b border-w-1')
  
      case 'grid':
        return setViewStyles('grid')
  
      default:
        return setViewStyles('selection')
    }
  }, [view]);

  return (
    <Link 
      ref={refProject} 
      to={`${type === 'selection' ? `/${content?.slug}`: `/projekt/${content?.slug}`}`} 
      preventScrollReset={true} 
      className={`relative block ${firstItem ? `lg:h-[calc(100vh-2.5vw)] pt-[2.5vw] lg:w-auto overflow-hidden ${view === 'list' ? 'border-t' : ''}` : ''} ${view === 'selection' ? width : ''} group ${viewStyles}`}
      
    >
      <div className={`relative ${firstItem ? 'h-full lg:col-span-12' : centered}`}>
        {view !== 'list' && content?.img && 
          <Image
            img={content?.img}
            altTxtSubtitle={content?.subtitle}
            preview="home"
            view={view}
            firstItem={firstItem}
          />}

        {view === 'list' ?
          <div className='relative flex flex-row justify-between my-1.5 lg:my-3 lg:gap-x-8 2xl:gap-x-12 lg:hover:text-green'>
            <p 
              className={`basis-4/5 lg:basis-7/12 xl:basis-4/12 text-sm lg:text-medium`}
              dangerouslySetInnerHTML={{__html: content?.title}}
            />
            <p className={`hidden lg:block lg:basis-[100px] text-sm lg:text-medium text-right whitespace-nowrap`}>{content.project_time ? content.project_time : content?.year}</p>
            <p className={`hidden lg:block lg:basis-4/12 xl:basis-2/12 text-sm lg:text-medium`}>{content?.location}</p>
            <p className={`hidden xl:block xl:basis-3/12 grow text-sm lg:text-medium`}>
              {content?.categories?.map((el,i) => el !== 'WETTBEWERBE' && <span key={i}>{el}{i < content?.categories?.length-1 ? ', ' : ''}</span>)}
            </p>
            <p className={`hidden 2xl:block 2xl:grow text-sm lg:text-medium text-right whitespace-nowrap`}>{content?.subtitle}</p>
          </div>
          :
          firstItem ? 
            <div className={`lg:absolute lg:w-full lg:h-full lg:inset-0 lg:flex lg:items-center lg:justify-center z-10 transition-opacity duration-300 lg:group-hover:opacity-100 lg:opacity-0 bg-white/50`}>
              <div className=''>
                <p 
                  className={`text-sm lg:text-lg`}
                  dangerouslySetInnerHTML={{__html: content?.title}}
                />
                <p className={`text-sm lg:text-lg`}>{content?.location}</p>
              </div>
            </div>
            :
            <div className={`relative w-full my-3 block ${view === 'selection' ? 'lg:group-hover:opacity-100 lg:opacity-0 transition-opacity duration-300' : ''}`}>
              <p
                className={`relative text-sm lg:text-medium ${type === 'selection' ? '' : 'font-folioMedium'} ${view === 'grid' ? `lg:group-hover:opacity-0 lg:opacity-100 transition-opacity duration-300` : ''}`}
                dangerouslySetInnerHTML={{__html: 
                  size.width >= 1024 ? 
                    content?.title
                    : 
                    (content?.subtitle ? content?.subtitle.concat(' ', content?.title) : content?.title)}}
              />
              <p className={`text-sm lg:text-medium ${type === 'selection' ? 'font-folioMedium' : ''} ${view === 'grid' ?  'lg:group-hover:opacity-0 lg:opacity-100 transition-opacity duration-300':''}`}>
                {view === 'grid' ? content?.location : content?.shortInfo}
              </p>
              
              {view === 'grid' && content?.subtitle && 
                <p className={`hidden lg:block ${view === 'grid' ? `lg:absolute top-0 lg:group-hover:opacity-100 lg:opacity-0 transition-opacity duration-300` : ''} text-sm lg:text-medium`}>
                  {content?.subtitle}
                </p>}
            </div>}
      </div>
    </Link>
  )
}

export default Project