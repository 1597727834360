import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeContext"

function LogoSchmidt() {
  
  const { dark, introEnd, showLogo } = useContext(ThemeContext)

  return (
    <svg
        className={`relative invisible md:visible lg:block transition-opacity duration-500 ${dark ? 'text-white' : 'text-black'} z-40`}
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        preserveAspectRatio="xMinYMax meet"
        viewBox="0 0 1213.92 208.006"
    >
      <path
        d="M30.67 58.006c-.2-23.86 11.73-39 47.93-39.37 26.88-.23 52.47 9.57 52.47 40.77h21.28C150.08 19.126 124-.394 76.24.006c-39.49.35-67.31 18.42-66.95 59.28.69 79.26 124.47 25.25 125 89.15.19 21.39-20.21 40.49-53.4 40.78-33.73.3-57.7-11.65-59.66-47.84H0c1.77 45.79 35.55 66.89 77 66.53 52.66-.46 79-29.49 78.73-61-.73-85.04-124.57-32.9-125.06-88.9"
        className={`${showLogo ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300 logo-initial`} 
      />
      <path
        d="M166.89 180.316H188.17V201.45600000000002H166.89z"
        className={`${showLogo ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300 logo-dot `} 
      />
      <path
        d="M296.37 187.776c-44-1.16-76.23-37.23-76.23-83.61s32.24-82.5 76.31-83.62h.17c28.51 0 54.29 12.24 64 38.86h22.07c-9.54-37.17-42-59.08-86.11-59.08h-.17c-56 1.62-99.28 47.47-99.28 103.84 0 56.37 43.34 102.23 99.32 103.84h.17c42.31 0 77.87-23.37 88.94-66.62H363c-5.32 27.84-35.64 46.4-66.36 46.4h-.25"
        className={`opacity-0 transition-opacity duration-300 logo-letter logo-name`} 
      />
      <path
        d="M542.4 201.316L563.7 201.316 563.7 6.356 542.4 6.356 542.4 93.626 438.37 93.626 438.37 6.356 417.07 6.356 417.07 201.316 438.37 201.316 438.37 112.196 542.4 112.196 542.4 201.316z"
        className={`opacity-0 transition-opacity duration-300 logo-letter logo-name`} 
      />
      <path
        d="M778.34 201.306L799.63 201.306 799.63 6.346 778.88 6.346 699.42 170.726 621.87 6.346 598.66 6.346 598.66 201.306 619.96 201.306 619.96 51.396 620.51 51.396 690.41 201.306 707.34 201.306 777.79 51.396 778.34 51.396 778.34 201.306z"
        className={`opacity-0 transition-opacity duration-300 logo-letter logo-name`} 
      />
      <path
        d="M834.59 6.296H856.0400000000001V201.316H834.59z"
        className={`opacity-0 transition-opacity duration-300 logo-letter logo-name`} 
      />
      <path
        d="M1037.63 103.826c0 45.06-32.22 78.92-80.82 78.92H912.3V24.916h44.51c48.6 0 80.82 33.86 80.82 78.91m23.49 0c0-58.16-42-97.48-102.13-97.48H891v195h68c60.08 0 102.13-39.33 102.13-97.49"
        className={`opacity-0 transition-opacity duration-300 logo-letter logo-name`} 
      />
      <path
        d="M1067.92 6.296L1067.92 24.686 1129.78 24.686 1129.78 201.306 1151.79 201.306 1151.79 24.966 1213.92 24.966 1213.92 6.296 1067.92 6.296z"
        className={`opacity-0 transition-opacity duration-300 logo-letter logo-name`} 
      />
    </svg>
  )
}

export default LogoSchmidt