import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactGA from 'react-ga4'

import { ThemeContextProvider } from './ThemeContext'
// import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import { ProjectsHeadlineProvider } from './ProjectsHeadlineContext'
import { PageScrolledProvider } from './PageScrolledContext'
import App from './App'

import './index.css'

const TRACKING_ID = 'G-NKYMEJCZMP'
ReactGA.initialize(TRACKING_ID)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <PageScrolledProvider>
        <ProjectsHeadlineProvider>
          <Router>
            <App />            
          </Router>
        </ProjectsHeadlineProvider>
      </PageScrolledProvider>
    </ThemeContextProvider>
  </React.StrictMode>
)