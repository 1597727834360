import React, { useContext, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Logo from '../Logo/Logo'
import Menubar from '../Menubar/Menubar'
import { ThemeContext } from '../../ThemeContext'
import { PageScrolledContext } from '../../PageScrolledContext'
import { Helmet } from 'react-helmet'
import { useWindowScroll, useWindowSize } from '@uidotdev/usehooks'

import faviconP from '../../assets/favicon-p.ico'
import faviconW from '../../assets/favicon-w.ico'
import faviconS from '../../assets/favicon-s.ico'

const Layout = () => {

  const { dark, setDark, setShowIntro, introEnd, setIntroEnd, setShowLogo, setHideLogoHorizontal, setMenuOpen, menuOpen, setProjectsView, isLoading, setLoading } = useContext(ThemeContext)
  const { setScroll } = useContext(PageScrolledContext)

  const [{ y }] = useWindowScroll()
  const size = useWindowSize()  

  const { pathname } = useLocation()

  const favicons = [faviconP, faviconW, faviconS]
  const [counter, setCounter] = useState(0)
  const [viewCount, setViewCount] = useState(0)
  const [menuData, setMenuData] = useState(null)
  const [contactData, setContactData] = useState(null)

  useEffect(() => {
    setScroll({
      isScrolled: y > 1 ? true : false,
      scrollPos: y,
      // scrollDirection: self?.direction,
    })  
  }, [y])

  useEffect(() => {
    if (pathname !== "/") {
      setTimeout(() => {
        setIntroEnd(true)
      }, size.width >= 1024 ? 3300 : 1)
    }
  }, [size, pathname]);
  
  useEffect(() => {
    // setLoading(true)
    setViewCount(viewCount + 1)
    setDark((pathname === '/' || pathname.includes('/projekt')) ? false : true)
    setMenuOpen(false)
    setHideLogoHorizontal(false)
    
    if (pathname === "/") {
      setShowIntro(true)
      setShowLogo(true)
      setProjectsView('selection')
    } else if (pathname.includes('/projekte')) {
      //  === "/projekte"
      setShowLogo(false)
      setProjectsView('grid')
    } else if (pathname.includes('/projekt/')) {
      setShowLogo(true)
    } else if (pathname === "/buero") {
      setShowLogo(true)
    } else {
      setShowLogo(false)
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [pathname])


  useEffect(() => {
    !menuOpen && (pathname === "/jobs" || pathname === "/impressum") && setShowLogo(false)
  }, [setShowLogo, menuOpen, pathname])

  useEffect(() => {
    fetch('https://cms.pws.eu/wp-json/pws/v1/menuItems')
      .then(res => res.json())
      .then(data => {
        setMenuData(data['menuMain'])
        setContactData(data['contactData'])
      })

    const interval = setInterval(() => setCounter((prevCounter) => prevCounter === 2 ? 0 : prevCounter + 1), 1000)
    return () => clearInterval(interval)
  }, [])
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Peter W. Schmidt Architekten</title>
        <meta name="description" content={'Peter W. Schmidt Architekten entwirft, plant und baut Wohnungsbauvorhaben, Bildungs- und Kulturbauten bis hin zu Industriebauten, Häusern und Stadträumen.'} />
        <link rel='icon' type='image' href={favicons[counter]} />
      </Helmet>

      <Logo viewCount={viewCount} />

      <Menubar
        menuData={menuData}
        contactData={contactData}
      />
      
      <div className={`pws-content relative px-[2.5vw] pb-[15vh] ${dark ? 'text-white' : 'text-black'} ${isLoading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300 delay-150 ease-in z-0`}>
        {introEnd && <Outlet />}
      </div>
    </>
  )
}

export default Layout


// const navigate = useNavigate()

  // const params = {category: filter}

  // const handleFilter = filter => {
  //   setFilter(null)
  //   setFilter(filter)
  // }  
  
  // useEffect(() => {
  //   if (splitLocation[1] === 'projekte' || splitLocation[1] === 'projekt') {
  //     if (filter === null) {
  //       navigate({pathname: pathname}) //'/projects'
  //     } else {
  //       navigate({
  //         pathname: pathname, //'/projects',
  //         search: `?${createSearchParams(params)}`
  //       })
  //     }
  //   }
  // }, [filter])



// const logoScrollTopPos = () => {
//   ScrollTrigger.create({
//     trigger: refWrapper.current,
//     start: 'top top',
//     // end: `top top-=100vh`,
//     scrub: true,
//     // markers: true,
//     onUpdate: self => {
//       (splitLocation.includes('projekt') && scroll?.scrollPos === 1 && scroll.scrollPos >= size?.height/5) && setShowLogo(false) 
//     }
//   })    
// }

// useLayoutEffect(() => {
//   let ctx = gsap.context(() => {
//     ScrollTrigger.create({
//       trigger: refWrapper.current,
//       start: 'top top',
//       // end: 'bottom bottom+=10px',
//       // markers: true,
//       onUpdate: self => {
//         setScroll({
//           isScrolled: self?.scroll() ? true : false,
//           scrollPos: self?.scroll(),
//           scrollDirection: self?.direction,
//         })  
//         // (splitLocation.includes('projekt') && scroll?.scrollPos === 1 && scroll.scrollPos >= size?.height/5) && setShowLogo(false) 
//         // self.scroll() >= 10 && setIsScrolled(true)
//       }
//     })
//   }, refWrapper.current)

//   return () => ctx.revert()
// }, [])

// useLayoutEffect(() => {
//   (splitLocation.includes('projekt') && scroll?.scrollPos === 1 && scroll.scrollPos >= size?.height/5) && setShowLogo(false) 
// }, [scroll])