import React, { useState, useRef, useEffect, useContext, useLayoutEffect }  from 'react'
import Image from '../Image/Image'
import { useParams } from 'react-router-dom'
import Gallery from '../Gallery/Gallery'
import ProjectData from '../ProjectData/ProjectData'
import ReadMoreText from '../ReadMoreText/ReadMoreText'
import { ThemeContext } from '../../ThemeContext'
import Projects from '../Projects/Projects'
import Video from '../Video/Video'

const SingleProject = () => {

  const { setLoading} = useContext(ThemeContext)

  const { slug } = useParams()

  const refSingleProject = useRef(null)
  const [singleProjectTitle, setSingleProjectTitle] = useState(null)
  const [singleProjectSubtitle, setSingleProjectSubtitle] = useState(null)
  const [singleProjectContent, setSingleProjectContent] = useState(null)
  const [singleProjectImages, setSingleProjectImages] = useState(null)
  const [singleProjectGallery, setSingleProjectGallery] = useState(null)
  const [singleProjectText, setSingleProjectText] = useState(null)
  const [singleProjectData, setSingleProjectData] = useState(null)
  const [singleProjectVideo, setSingleProjectVideo] = useState(null)
  const [hoveredTxt, setHoveredTxt] = useState(null)

  useEffect(() => {  
    fetch(`https://cms.pws.eu/wp-json/wp/v2/projects/?slug=${slug}`)
      .then(res => res.json())
      .then(data => {
        setSingleProjectTitle(data[0]?.title.rendered.replace(" &#8211; Copy", ""))
        setSingleProjectSubtitle(data[0]?.acf.project_subtitle)
        setSingleProjectContent(data[0]?.acf)
        setSingleProjectImages(data[0]?.acf.project_content?.filter(el => el.acf_fc_layout === 'project_content_img' || el.acf_fc_layout === 'project_content_video'))
        setSingleProjectGallery(data[0]?.acf.project_content?.filter(el => el.acf_fc_layout === 'project_content_gallery'))
        setSingleProjectText(data[0]?.acf.project_content?.filter(el => el.acf_fc_layout === 'project_content_txt'))
        setSingleProjectData(data[0]?.acf.project_content?.filter(el => el.acf_fc_layout === 'project_content_data'))
        setSingleProjectVideo(data[0]?.acf.project_content?.filter(el => el.acf_fc_layout === 'project_content_video'))
        setLoading(false)
      })  
  }, [slug])

  return (
    <>
      <div ref={refSingleProject} className='relative flex flex-wrap justify-center min-h-screen'>
        <div className='relative w-full h-full mb-8 lg:mb-24 xl:mb-32 max-lg:mt-8'> 
          <Gallery 
            images={singleProjectGallery?.[0]?.gallery_images} 
            fullscreen={true} 
            type="loop"
            arrows={true} 
            autoplay={3000} 
            autoPlayActive={true} 
            pagination={true} 
            isPost={false}
            isProject={true} 
          />
        </div>

        <div className='z-30 w-full pt-[2.5vw] mb-4 bg-white lg:sticky lg:top-0 lg:pb-6'>
          <div className=''>
            <h1 
              className='!mb-0 text-base lg:text-lg'
              dangerouslySetInnerHTML={{__html: singleProjectTitle}}
            />

            <p className='mb-4 text-medium lg:text-base'>
              {singleProjectContent?.project_location}
            </p>
          </div>  
        </div>

        <div className='relative flex flex-col w-full max-w-screen-xl mb-8 mr-auto lg:flex-row gap-x-12 lg:mb-32 xl:mb-48'> 
          {singleProjectText?.[0]?.project_txt && 
            <div className={`relative w-full lg:w-1/2 mb-8 lg:mb-16 xl:mb-24 project-txt`}>
              <div className='relative text-sm text-left lg:text-base lg:m-auto'>
                <ReadMoreText 
                  text={singleProjectText?.[0]?.project_txt} 
                  type={'project'} 
                  isText={true} 
                  hoveredTxt={hoveredTxt} 
                /> 
              </div>
            </div>}           

          <div className='relative w-full lg:w-1/2 project-data'>
            <ProjectData projectData={singleProjectData?.[0]?.project_content_data_table} />
          </div>
        </div>

        <div className='relative w-full flex flex-wrap justify-between lg:gap-x-[12.5%] lg:mb-[10vh]'>  
          {singleProjectImages?.map((el, i) => {
            const imgCenter = el.project_img_centered
            const width = el.acf_fc_layout === 'project_content_video' ? 'lg:w-5/12 video' : (el.project_img.width >= el.project_img.height ? `${imgCenter ? 'lg:w-1/2' : 'lg:w-5/12'}` : `${imgCenter ? 'lg:w-5/12' : 'lg:w-1/4'} lg:cursor-pwsPlus`)
            // const width = el.project_img.width >= el.project_img.height ? `${imgCenter ? 'lg:col-span-6 lg:col-start-4' : 'lg:col-span-5'}` : `${imgCenter ? 'lg:col-span-5' : 'lg:col-span-3'}`
            return (
              <div
                key={i} 
                id={i}
                className={`${imgCenter ? 'w-full flex justify-center' : width} mb-12 lg:mb-24`}//${i >= 1 && i % 2 !== 0 ? (width === 'lg:col-span-5' ? 'lg:col-start-8' : 'lg:col-start-10') : ''} 
              >
                {el.acf_fc_layout === 'project_content_video' ? 
                  <Video src={el.project_video} play={false} />
                  :
                  <div className={`${imgCenter ? width : ''}`}>
                    {el.project_img && 
                      <Image 
                        img={el.project_img} 
                        isModal={true} 
                        preview 
                        contentIndex={i}
                      />}
                  </div>}
              </div>
            )
          })}
        </div>
      </div>

      <Projects />        
    </>
  )
}

export default SingleProject