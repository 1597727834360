import React, { useEffect, useRef, useState } from 'react'
// import { gsap } from 'gsap'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Link } from 'react-router-dom'
import Image from '../Image/Image'
import ReadMoreText from '../ReadMoreText/ReadMoreText'
import Video from '../Video/Video'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'

const Post = ({index, content, width, firstItem}) => {
  // gsap.registerPlugin(ScrollTrigger)

  const refPost = useRef(null)
  
  const [inViewport, setInViewport] = useState(false)
  const [postClamped, setPostClamped] = useState(true)
  const [showTxt, setShowTxt] = useState(false)

  // const postScrollTrigger = () => {
  //   ScrollTrigger.create({
  //     trigger: refPost.current,
  //     start: `top center`,
  //     end: `bottom top`,
  //     onEnter: () => setInViewport(!inViewport),
  //     onEnterBack: () => setInViewport(!inViewport),
  //     onLeave: () => setInViewport(!inViewport),
  //     onLeaveBack: () => setInViewport(!inViewport)
  //   })
  // }

  // useEffect(() => {
  //   postScrollTrigger()
  // }, [])
  useEffect(() => {
    console.log(postClamped)
  }, [postClamped])

  return (
    <div ref={refPost} className={`relative w-full ${width} transition-colors md:px-0 group`}>
      <div className='relative w-full pb-4 md:pb-2 z-10 bg-transparent md:backdrop-blur-lg md:backdrop-brightness-1.25 cursor-pointer'>
        <div onClick={() => setShowTxt(!showTxt)}>
          {/* setPostClamped(!postClamped) */}
          {content?.img?.length > 1 &&
            <Splide 
              tag='section'
              hasTrack={false}
              className={`relative z-0 w-full aspect-16/10 pointer-events-none`} 
              aria-label='Bildergalerie'
              options={{
                type: 'loop', 
                autoWidth: true,
                padding: 0,
                gap: 0,
                easing: 'cubic-bezier(0.250, 0.250, 0.750, 0.750)',
                autoplay: true,
                pauseOnHover: false,
                interval: 7000,
                speed: 750,
                rewind: false,
                arrows: false,
                pagination: false
              }}
              // onMounted={splide => setSplideInstance(splide)}
              // onMounted={splide => splide.Components.Autoplay.pause()}
            >
              <SplideTrack>
                {content?.img?.map((el, i) => 
                  <SplideSlide key={i} className={`aspect-16/10`}>
                    <Image 
                      img={el} 
                      preview={'home'} 
                      galleryImg={true} 
                      isModal={false} 
                      isPost={true}                       
                    />
                  </SplideSlide>
                )}
              </SplideTrack>
            </Splide>}
            
            {content?.img?.length === 1 && 
              <Image
                img={content.img ? content.img[0] : null} 
                preview={'home'}
                isModal={false} 
                isPost={true} 
                // postClamped={postClamped} 
                // setPostClamped={setPostClamped} 
              />}
        </div>
            
        {content?.video && 
          <div className='mb-4'>
            <Video src={content.video} play={inViewport} />
          </div>}
              
        <div className='relative my-3 text-medium post-text' onClick={() => setShowTxt(!showTxt)}>
          {content.news_subHeadline && <p className='text-sm text-black lg:text-medium'>{content.news_subHeadline}</p>}

          <h3 className={`mb-2 font-folioMedium ${content?.headlineLarge ? 'text-base lg:text-large' : 'text-sm lg:text-medium'} text-black group-hover:text-green`}>
            {content?.title}
          </h3>

          {showTxt && <>
            <div 
              className={`text-black text-sm lg:text-medium`}
              dangerouslySetInnerHTML={{__html: content.newsTxt }}
            />
            {content.news_link && <SwitchLinkType link={content.news_link} />}
          </>}

          <div className='flex w-full mt-2.5'>
            <button 
              type='button'
              className={`flex items-center font-folioLight leading-none z-30 focus:outline-none focus:ring-0 focus:ring-inset-0 text-black ${showTxt ? '' : 'rotate-45'}`}
              onClick={() => setShowTxt(!showTxt)}
            >
                <svg className='w-3 h-3' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
                  <path strokeLinecap="square" strokeLinejoin="square" strokeWidth="3" d={showTxt ? "M 0 12 L 24 12" : "M 0.339 23.661 L 23.661 0.339 M 0.339 0.339 L 23.661 23.661" }/>
                </svg>
            </button>
          </div>
          
          {/* {content?.newsTxt && 
            <ReadMoreText 
              text={content.newsTxt} 
              hasLink={content.news_link ? true : false} 
              type={'news'} 
              isText={true} 
              postClamped={postClamped}
            >
              <div 
                className={`text-black text-sm lg:text-medium mb-3`}
                dangerouslySetInnerHTML={{__html: content.newsTxt }}
              />
              
              {content.news_link && <SwitchLinkType link={content.news_link} />}
            </ReadMoreText>} */}
        </div>        
      </div>
    </div>
  )
}

export default Post



const SwitchLinkType = ({link}) => {
  switch (link.post_type) {
    case 'projects':
      return (
        <Link to={`/projekt/${link.post_name}`} className="block underline !decoration-green underline-offset-2 text-green text-sm lg:text-medium font-folioMedium px-0">
          zum Projekt
        </Link>
      )

    case 'job':
      return (
        <Link to={`/page/jobs/${link.post_name}`} className="block underline !decoration-green underline-offset-2 text-green text-sm lg:text-medium font-folioMedium px-0">
          zur Stellenausschreibung
        </Link>
      )

    case 'page':
      return (
        <Link to={`/${link.post_name}`} className="block underline !decoration-green underline-offset-2 text-sm lg:text-medium text-green font-folioMedium px-0">
          {link.post_title}
        </Link>
      )

    default:
      return null;
  }
}