import { useContext, useRef, useState } from "react"
import Searchbar from "../Search/Searchbar"
import { ThemeContext } from "../../ThemeContext"
import { useWindowSize } from "usehooks-ts"

function FilterProjectView({ categories, setFilter, filter, searchProjects}) {

  const { setShowLogo, projectViews, projectsView, setProjectsView, isSearch, setIsSearch } = useContext(ThemeContext)
  
  const { width } = useWindowSize()

  const refFilter = useRef(null)
  const refFilterBtn = useRef(null)

  const toggleSearch = val => setIsSearch(val)

  const [showFilter, setShowFilter] = useState(false)

  return (
    <div className={`sticky z-30 ${projectsView === 'selection' ? 'lg:col-span-12' : (projectsView === 'list' ? 'col-span-12' : 'col-span-2 md:col-span-3 lg:col-span-4')} bg-white top-0 flex flex-col justify-between lg:pb-8`}>
      
      <div className='relative w-full pt-[2.5vw]'>
        <h1 className='mb-4 uppercase text-medium font-folioMedium'>Projekte</h1>
        
        <ul className='flex max-lg:flex-wrap gap-x-12 lg:gap-x-6'>
          {projectViews.map(el => 
            <li key={el.value} 
              className={`cursor-pointer`} 
              onClick={() => {
                setProjectsView(el.value)
                // setShowLogo(false)
                width < 1024 && setShowFilter(false)
                document.getElementById('projects')?.scrollIntoView({
                  behavior: 'smooth'
                })
              }}
            >
              <span className={`${projectsView === el.value ? 'border-b border-1' : ''}`}>
                {el.name}
              </span>
            </li>)}

          <li className="max-lg:flex-none max-lg:w-full max-lg:mt-3">
            {isSearch ? 
              <Searchbar toggleSearch={toggleSearch} searchProjects={searchProjects} /> 
              :
              <span onClick={() => toggleSearch(true)}>Suche</span> }
          </li>
        </ul>
      </div>

      {projectsView !== 'selection' && <div className={`relative mt-3 lg:mt-4 flex flex-col w-full`}> 
        <p 
          ref={refFilterBtn} 
          className={`relative text-medium whitespace-nowrap cursor-pointer`}
          onClick={() => setShowFilter(!showFilter)}
        >
          <span className="inline-block w-3 mr-0.5">{showFilter ? '-' : '+'}</span>
          <span>Filter</span>
        </p>          
      
        <ul ref={refFilter} className={`relative mt-2 lg:mt-4 lg:ml-24 z-50 w-full flex flex-col gap-y-1 cursor-pointer ${showFilter ? 'h-auto max-lg:pb-3' : 'h-0 overflow-hidden'}`}>
          {categories?.map((el, i) => 
            el.slug !== 'archiv' && el.slug !== 'all' && el.slug !== 'dev' &&
              <li key={i}
                className={`${el.name === filter ? 'font-folioMedium' : ''} capitalize hover:font-folioMedium whitespace-nowrap text-sm`}
                onClick={() => setFilter(filter === el.name ? null : el.name)}
              >
                {el.name}
              </li>
          )}
        </ul>
      </div>}
    </div>
  )
}

export default FilterProjectView