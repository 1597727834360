import React, { useContext, useEffect, useRef } from "react"
import { ThemeContext } from "../../ThemeContext"
// import { useWindowSize } from '@uidotdev/usehooks'

function LogoW() {

    // const {width} = useWindowSize()
    const { dark, showLogo, introEnd} = useContext(ThemeContext)

    const refLogoW = useRef(null)

    // const { setContentWidth } = useContext(ThemeContext)

    // useEffect(() => {
    //     setContentWidth(width >= 1024 ? width - ((refLogoW.current?.getBoundingClientRect().width + 32) * 2) : width)
    // }, [width])

    return (
        // <div className='absolute top-0 right-0 z-0 invisible w-full h-full lg:visible lg:flex lg:justify-end'>
            <svg ref={refLogoW} 
                className={`absolute top-0 right-0 z-0 invisible w-full h-full lg:visible lg:block object-contain object-right-top ${dark ? 'text-white' : 'text-black'}`} //relative w-auto h-full
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                preserveAspectRatio="xMaxYMin meet"
                viewBox="0 0 266 195.03"
            >
                <path
                    d="M230.21 0L193.94 138.953 189.57 157.638 187.63 157.638 183.27 138.953 147.09 0.05 147.09 0 104.51 0 68.22 138.983 63.86 157.638 61.92 157.638 57.64 139.293 21.29 0 0 0 52.24 195.02 52.27 195.02 73.53 195.02 73.56 195.02 120.53 19.646 131.06 19.646 178 194.89 177.97 195.02 178.04 195.02 199.25 195.02 199.32 195.02 199.29 194.89 251.49 0 230.21 0z"
                    className={`${showLogo ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300 logo-initial`}
                />

                <path
                    d="M244.58 173.83H265.86V194.97000000000003H244.58z"
                    className={`${showLogo ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300 logo-initial`}
                />
            </svg>
        // </div>
    )
}

export default LogoW